import { graphql } from 'gatsby';
import React from 'react';
import styled from 'styled-components';
import isNil from 'utils/isNil';
import ToolboxLayout from 'layouts/toolboxLayout';
import Pagination from 'components/Core/Pagination';
import PodcastFeaturedContent from 'components/Page/Podcast/FeaturedContent';
import PodcastAboutShowSection from 'components/Page/Podcast/AboutShowSection';
import ContentfulCardsBlock from 'components/Contentful/ContentfulCardsBlock';
import usePodcastLatestToolsEpisode from 'hooks/usePodcastLatestToolsEpisode';
import { truncateText } from 'utils/text';
import podcastIcon from 'images/podcast-white.svg';
import BookmarkContext from 'utils/BookmarkContext';
import PodcastSplashHero from '../components/Page/Podcast/SplashHero';
import usePodcastLatestMasteringSTEpisode from '../hooks/usePodcastLatestMasteringSTEpisode';

const PodcastPage = (props) => {
  const {
    allContentfulImage: pageImages,
    allContentfulPodcast,
    toolsComponents,
    masteringSTComponents,
  } = props.data;
  let favSpotify;
  let favPodcast;
  let favYoutubePodcast;

  const { currentPage, numPages, series } = props.pageContext;
  const isToolsPodcast = series === 'Toolbox for the Trades';

  const componentCollection = isToolsPodcast ? toolsComponents : masteringSTComponents;
  const latestToolsEpisode = usePodcastLatestToolsEpisode();
  const latestMasteringSTEpisode = usePodcastLatestMasteringSTEpisode();
  const latestEpisode = isToolsPodcast ? latestToolsEpisode : latestMasteringSTEpisode;

  const header = componentCollection.components.find(
    (component) => component.__typename === 'ContentfulHeader',
  );
  const hero = componentCollection.components.find(
    (component) => component.__typename === 'ContentfulLayout',
  );
  const conversionPanel = componentCollection.components.find(
    (component) => component.__typename === 'ContentfulConversionPanel',
  );

  const topics = latestEpisode?.seo?.taxonomy?.topics || ['All Topics'];

  pageImages.edges.forEach((imageItem) => {
    if (imageItem.node.internalName === 'podcast-favorite-spotify') {
      favSpotify = !isNil(imageItem.node.image) ? imageItem.node.image.file.url : '';
    } else if (imageItem.node.internalName === 'podcast-favorite-podcast') {
      favPodcast = !isNil(imageItem.node.image) ? imageItem.node.image.file.url : '';
    } else if (imageItem.node.internalName === 'podcast-favorite-youtube-music') {
      favYoutubePodcast = !isNil(imageItem.node.image) ? imageItem.node.image.file.url : '';
    }
  });

  const favorites = [
    {
      title: 'Spotify',
      logo: favSpotify,
      link:
        latestEpisode?.spotifyLink ||
        'https://open.spotify.com/show/76wMZ1SJV4OAql9HzbnQ87?si=66s37hbqRpu_4vQCaB1T3w',
    },
    {
      title: 'Podcasts',
      logo: favPodcast,
      link:
        latestEpisode?.applePodcastsLink ||
        'https://podcasts.apple.com/us/podcast/toolbox-for-the-trades/id1503078938',
    },
    {
      title: 'YouTube Music',
      logo: favYoutubePodcast,
      link:
        latestEpisode?.youtubePodcastLink || series === 'Toolbox for the Trades'
          ? 'https://music.youtube.com/playlist?list=PLfbEYZ7T-01ZSOJOB9DYDlOW8RRkEv77- '
          : 'https://www.youtube.com/playlist?list=PLfbEYZ7T-01ZYOCqrLdnXKVBSuA6J3U1q',
    },
  ];

  const cardBlock = {
    description: '',
    bgColor: 'Alice Blue',
    cardType: 'Image',
    bodyType: 'simple',
    cards: allContentfulPodcast.edges
      .map((node) => node.node)
      .filter((podcast) => podcast.id !== latestEpisode.id)
      .map((episode) => {
        const podcastExcerpt =
          episode.podExcerpt?.podExcerpt ||
          JSON.parse(episode.excerpt?.raw)?.content[0]?.content[0]?.value;
        const descriptionTrunc = truncateText(podcastExcerpt, 90);
        return {
          subTitle: `${episode.seo?.taxonomy?.topics?.join(' • ')} • ${episode.minutes} minutes`,
          title: episode.podcastTitle,
          description: {
            description: descriptionTrunc,
          },
          cover: !isNil(episode.cardPhoto?.image)
            ? episode.cardPhoto?.image
            : episode.coverPhoto?.image,
          ctaLink: episode.seo.pageUrl,
          image: episode?.cardPhoto?.image?.file?.url,
        };
      }),
  };

  const breadcrumbs = {
    data: [
      {
        title: 'Toolbox',
        icon: null,
        url: '/toolbox',
      },
      {
        title: 'Podcasts',
        icon: podcastIcon,
        url: '/podcasts',
      },
      {
        title: series,
        icon: null,
        url: '',
        hideInMobile: true,
      },
    ],
    absolute: true,
    theme: 'light',
  };

  return (
    <Container>
      <ToolboxLayout
        breadcrumbs={breadcrumbs}
        contentfulSeo={
          isToolsPodcast ? props.data.toolboxForTheTradesSeo : props.data.masteringServiceTitanSeo
        }
        header={header}
        theme="TRANS_BG_WHITE_COLOR"
        layout="industry"
        {...props}
      >
        <PodcastSplashHero {...hero} favorites={favorites} />
        <PodcastFeaturedContent
          subTitle={`${topics.join(' • ')} • ${latestEpisode?.minutes} minutes`}
          title={latestEpisode?.podcastTitle}
          description={
            latestEpisode
              ? truncateText(
                  JSON.parse(latestEpisode.excerpt?.raw)?.content[0]?.content[0]?.value,
                  125,
                )
              : null
          }
          cardPhoto={latestEpisode?.cardPhoto}
          coverPhoto={latestEpisode?.coverPhoto}
          detailLink={latestEpisode?.seo?.pageUrl}
          aushaEpisodeId={latestEpisode?.aushaEpisodeId}
          favorites={favorites}
          series={series}
        />
        {allContentfulPodcast.edges.length > 0 && (
          <BookmarkContext.Provider value={{ type: 'podcast' }}>
            <ContentfulCardsBlock anchorId="card-group" cols={3} {...cardBlock} />
          </BookmarkContext.Provider>
        )}
        {currentPage > 0 && numPages > 0 && (
          <Pagination
            template={
              isToolsPodcast ? 'podcasts/toolbox-for-the-trades' : 'podcasts/mastering-servicetitan'
            }
            anchorId="card-group"
            pageContext={{
              currentPage,
              numPages,
            }}
          />
        )}
        {conversionPanel && <PodcastAboutShowSection content={conversionPanel} />}
      </ToolboxLayout>
    </Container>
  );
};

export default PodcastPage;

export const PodcastPageQuery = graphql`
  query PodcastPageQuery($items: [String!]) {
    allContentfulImage(
      filter: {
        contentful_id: {
          in: [
            "3c6QsgzhIBl3kbaz7o9SDJ" #podcast-favorite-spotify
            "2tJ0HouccbJrmiZ5Vi4wOK" #podcast-favorite-podcast
            "27psQeg4B68PBAREykYDlT" #podcast-favorite-youtube-music
          ]
        }
      }
    ) {
      edges {
        node {
          contentful_id
          updatedAt
          internalName
          alt
          image {
            file {
              url
            }
            ...Image
          }
        }
      }
    }
    allContentfulPodcast(
      filter: { contentful_id: { in: $items } }
      sort: { order: DESC, fields: podcastDate }
    ) {
      edges {
        node {
          id
          podcastTitle
          podcastDate(locale: "")
          seo {
            pageUrl
            taxonomy {
              industries
              topics
            }
          }
          series
          minutes

          podExcerpt {
            podExcerpt
          }
          excerpt {
            raw
          }
          cardPhoto {
            image {
              ...Image
            }
          }
          coverPhoto {
            image {
              ...Image
            }
          }
        }
      }
    }
    toolboxForTheTradesSeo: contentfulSeo(contentful_id: { eq: "3Cb3N5BWNaBItN6srJOoms" }) {
      ...Seo
    }
    masteringServiceTitanSeo: contentfulSeo(contentful_id: { eq: "3fhF7TzHai63Gd5JgmKudL" }) {
      ...Seo
    }
    masteringSTComponents: contentfulComponentCollection(
      contentful_id: { eq: "3TGP0Bz0J97iSDy7X8vViR" }
    ) {
      ... on ContentfulComponentCollection {
        components {
          ...Header
          ... on ContentfulLayout {
            __typename
            componentMap
            stylesEntries: styles {
              ...Styles
            }
            section: sectionContent {
              ...Section
            }
            richText {
              ...RichText
            }
            template {
              ...Section
            }
          }
          ...ConversionPanel
        }
      }
    }
    toolsComponents: contentfulComponentCollection(
      contentful_id: { eq: "6zmfMponTnXFcCbE83SdTa" }
    ) {
      ... on ContentfulComponentCollection {
        components {
          ...Header
          ... on ContentfulLayout {
            __typename
            componentMap
            stylesEntries: styles {
              ...Styles
            }
            section: sectionContent {
              ...Section
            }
            richText {
              ...RichText
            }
            template {
              ...Section
            }
          }
          ...ConversionPanel
        }
      }
    }
  }
`;

const Container = styled.div`
  .section-layout--inner-container {
    padding-top: 0px;
  }

  .website-header .website-header--main-menu {
    background: transparent;
  }

  .website-header.scrolled .website-header--main-menu {
    background: var(--white);
  }

  .main-container {
    padding: 0;
  }

  .podcast-hero {
    padding: 350px 28px 100px 28px !important;

    @media (max-width: 1199px) {
      padding: 265px 28px 100px 28px !important;
    }

    @media (max-width: 767px) {
      padding: 250px 28px 75px 28px !important;
    }
  }

  @media (max-width: 575px) {
    .dropdown-select--container {
      padding: 0 20px;
    }
  }
`;
